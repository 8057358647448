import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layouts/Default"
import Breadcrumb from "../../components/common/Breadcrumb"
import Container from "../../components/layouts/container/Container"
import SectionTitle from "../../components/common/SectionTitle"
import Cta from "../../components/common/Cta"
import HeaderImage from "../../components/common/HeaderImage"
import ColumnsThree from "../../components/layouts/container/ColumnsThree"
import ServiceSidebar from "../../components/common/ServiceSidebar"
import P from "../../components/typography/P"

export default function Page({ data, location }) {
  const svg = {
    check: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    ),
  }
  return (
    <Layout
      location={location}
      title="Erstellung Exposé | Döbbel Immobilien"
      name="Leistungen"
      desc=""
    >
      <HeaderImage img={data.header.childImageSharp.fluid} />
      <Breadcrumb
        links={[
          { link: "Leistungen", to: "/leistungen/" },
          { link: "Erstellung Exposé", to: "/leistungen/expose/" },
        ]}
      />
      <Container className="my-8 md:my-16">
        <ColumnsThree>
          <div className="md:col-span-2">
            <SectionTitle
              title="Erstellung Exposé"
              subtitle="Leistungen"
              text="Die Grundlage für einen erfolgreichen und zeitnahen Verkauf eines Hauses oder einer Wohnung."
              className="mb-6"
            />
            <P className="mb-3">
              Ein Exposé ist die bildhafte und textliche Beschreibung einer
              Immobilie für den Verkauf, also sozusagen die Visitenkarte eines
              Hauses oder einer Wohnung. Die Gestaltung des Exposés kann die
              Entscheidung von Kaufinteressenten maßgeblich beeinflussen.
            </P>
            <P className="mb-3">
              Ein ansprechendes und aussagekräftiges Exposé erhöht die
              Verkaufschancen und beantwortet im Vorfeld viele Fragen. So können
              Sie Besichtigungstourismus vermeiden und müssen weniger
              Besichtigungen vorbereiten. Sie bleiben mit Ihrem Exposé in guter
              Erinnerung und schaffen eine erste Vertrauensbasis bei
              Kaufinteressenten.
            </P>
            <P className="mb-3">
              Ein gutes Exposé kann das „besondere Etwas“ an Ihrem
              Verkaufsobjekt gezielt zur Geltung bringen. Es weckt auf den
              ersten Blick Interesse bei potenziellen Käufern und stellt ein
              wichtiges Entscheidungskriterium zur Besichtigung und letztendlich
              zum erfolgreichen Immobilienverkauf dar.
            </P>
          </div>
          <div>
            <ServiceSidebar name="Erstellung Exposé" />
          </div>
        </ColumnsThree>
      </Container>

      <Container className="my-8 md:my-16">
        <Cta />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "expose.jpg" }) {
      childImageSharp {
        fluid(jpegQuality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
